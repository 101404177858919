import validate from "/usr/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_2kb3gpyadf3doc6frtdheajbri/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/usr/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_2kb3gpyadf3doc6frtdheajbri/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "is-stock-portfolio-type-valid": () => import("/usr/src/packages/stocks/middleware/is-stock-portfolio-type-valid.ts"),
  "authenticated-or-login": () => import("/usr/src/apps/base/src/middleware/authenticated-or-login.ts"),
  "existing-product-id": () => import("/usr/src/apps/base/src/middleware/existing-product-id.ts"),
  "not-authenticated-only": () => import("/usr/src/apps/base/src/middleware/not-authenticated-only.ts")
}