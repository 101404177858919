<template>
  <slot />
</template>

<script lang="ts" setup>
/**
 * Do not delete useI18n composable usage. It is required for the useProvidePricingStore composable to read it from.
 */
useI18n({ useScope: "local" })
</script>

<i18n lang="json">
{
  "en": {
    "products": {
      "PRODUCT_STOCK": {
        "id": "PRODUCT_STOCK",
        "path": "/stocks",
        "label": "Stocks",
        "discount": {
          "period": "ANNUAL",
          "percentage": 10
        },
        "plans": {
          "DIY": {
            "title": "S&P 500 Stocks DIY",
            "description": "Science-based insights for smarter stocks investing",
            "header_background": "/pricing/card_PRODUCT_STOCK.png",
            "features": [
              {
                "title": "STOCKS DEPOT",
                "description": "Daily-updated ranking of all S&P 500 stocks"
              },
              {
                "title": "FINQFIRST portfolio",
                "description": "Our top 10 stocks to buy (long strategy)"
              },
              {
                "title": "FINQLAST portfolio",
                "description": "Our top 10 stocks to short-sell (short strategy)"
              },
              {
                "title": "FINQNEUTRAL portfolio",
                "description": "Our top 10 stocks to buy + 10 to short-sell (dual strategy)"
              }
            ],
            "terms": [
              {
                "id": "agreement",
                "disclaimer": "I hereby confirm that I subscribe to the service for my personal use only and that I will not use the information obtained from the service for the purpose of providing investment recommendations or for managing investments for others. This service and the rating of the shares presented here do not constitute a recommendation for their purchase. The rating provided does not imply investment advice and/or investment marketing; it is for informational purposes only. Anyone using this information does so at their own risk. The company will have no responsibility for the customer's action or inaction as a result of using this information. The provision of the service is not a substitute for advice that takes into account the data and the special needs of each person. The company has no personal interest in the rated shares."
              }
            ]
          },
          "MANAGED": {
            "title": "S&P 500 Stocks MANAGED",
            "description": "A to Z science-based stocks portfolio management",
            "header_background": "/pricing/card_PRODUCT_STOCK_managed.png",
            "features": [
              {
                "title": "STOCKS DEPOT",
                "description": "Daily-updated ranking of all S&P 500 stocks"
              },
              {
                "title": "FINQFIRST portfolio",
                "description": "Our top 10 stocks to buy (long strategy)"
              },
              {
                "title": "FINQLAST portfolio",
                "description": "Our top 10 stocks to short-sell (short strategy)"
              },
              {
                "title": "FINQNEUTRAL portfolio",
                "description": "Our top 10 stocks to buy + 10 to short-sell (dual strategy)"
              },
              { "title": "Hassle-free execution of any investment strategy" },
              {
                "title": "Maximum potential returns",
                "description": "When short-selling, we typically invest 70-80 cents for every $1 position (with adjustments directed by brokers)"
              }
            ]
          }
        }
      },
      "PRODUCT_FUND": {
        "id": "PRODUCT_FUND",
        "path": "/funds",
        "label": "Funds",
        "discount": {
          "period": "ANNUAL",
          "percentage": 20
        },
        "plans": {
          "DIY": {
            "title": "Funds DIY",
            "description": "Science-based insights for smarter mutual funds and ETFs investing",
            "header_background": "/pricing/card_PRODUCT_FUND.png",
            "features": [
              {
                "title": "FUNDS DEPOT",
                "description": "Daily-updated ranking of all American mutual funds and ETFs. Explore and compare to pick your optimal funds"
              },
              {
                "title": "FINQCRAFT mutual funds portfolio",
                "description": "5 funds, selected through AI-driven analysis of millions of combinations, perfectly tailored to your chosen risk level"
              },
              {
                "title": "FINQCRAFT ETFs portfolio ",
                "description": "5 funds, selected through AI-driven analysis of millions of combinations, perfectly tailored to your chosen risk level"
              }
            ]
          },
          "MANAGED": {
            "title": "Funds MANAGED",
            "description": "A to Z science-based mutual funds and ETFs portfolio management",
            "header_background": "/pricing/card_PRODUCT_FUND_managed.png",
            "screenPath": "kyc/INV",
            "features": [
              {
                "title": "FUNDS DEPOT",
                "description": "Daily-updated ranking of all American mutual funds and ETFs. Explore and compare to pick your optimal funds"
              },
              {
                "title": "FINQCRAFT mutual funds portfolio",
                "description": "5 funds, selected through AI-driven analysis of millions of combinations, perfectly tailored to your chosen risk level"
              },
              {
                "title": "FINQCRAFT ETFs portfolio",
                "description": "5 funds, selected through AI-driven analysis of millions of combinations, perfectly tailored to your chosen risk level"
              },
              { "title": "Hassle-free execution of any investment strategy" }
            ]
          }
        }
      }
    }
  }
}
</i18n>
