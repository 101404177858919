import { default as _91_46_46_46404_93dMgKFsN6Q6Meta } from "/usr/src/packages/error-handling/pages/[...404].vue?macro=true";
import { default as about4C1BTiVyMhMeta } from "/usr/src/apps/base/src/pages/about.vue?macro=true";
import { default as accessibility08XNiHGQ0oMeta } from "/usr/src/apps/base/src/pages/accessibility.vue?macro=true";
import { default as contactwZAZ1OhQATMeta } from "/usr/src/apps/base/src/pages/contact.vue?macro=true";
import { default as datasecurityk9e8JnurInMeta } from "/usr/src/apps/base/src/pages/datasecurity.vue?macro=true";
import { default as disclosureIQD52U35CiMeta } from "/usr/src/apps/base/src/pages/disclosure.vue?macro=true";
import { default as forgotpassword3Ou1x7Xjk4Meta } from "/usr/src/apps/base/src/pages/forgotpassword.vue?macro=true";
import { default as indexqksRg9oJ1WMeta } from "/usr/src/apps/base/src/pages/index.vue?macro=true";
import { default as loginYIEPQC6mhGMeta } from "/usr/src/apps/base/src/pages/login.vue?macro=true";
import { default as partnersnKP1uP9IicMeta } from "/usr/src/apps/base/src/pages/partners.vue?macro=true";
import { default as passwordresetawaitFAii2hbFCfMeta } from "/usr/src/apps/base/src/pages/passwordresetawait.vue?macro=true";
import { default as _91_91productId_93_93hqPFitOqkYMeta } from "/usr/src/packages/pricing/pages/pricing/[[productId]].vue?macro=true";
import { default as pricingOIBELzIw3pMeta } from "/usr/src/packages/pricing/pages/pricing.vue?macro=true";
import { default as privacyW7FWgOcv9fMeta } from "/usr/src/apps/base/src/pages/privacy.vue?macro=true";
import { default as infoFL8Bz5DpZJMeta } from "/usr/src/apps/base/src/pages/profile/info.vue?macro=true";
import { default as profilezv8G5W5pJQMeta } from "/usr/src/apps/base/src/pages/profile.vue?macro=true";
import { default as signupD0zLV4v9noMeta } from "/usr/src/apps/base/src/pages/signup.vue?macro=true";
import { default as indexmzXPjhq5EgMeta } from "/usr/src/packages/stocks/pages/stocks/index.vue?macro=true";
import { default as indexlmLBSz9fOYMeta } from "/usr/src/packages/stocks/pages/stocks/momentum/index.vue?macro=true";
import { default as negative3xD1YXaAWuMeta } from "/usr/src/packages/stocks/pages/stocks/momentum/negative.vue?macro=true";
import { default as positivecfmu6LyIYtMeta } from "/usr/src/packages/stocks/pages/stocks/momentum/positive.vue?macro=true";
import { default as indexPDJrGGTxBuMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/index.vue?macro=true";
import { default as returnsnxcTGiYrnyMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/returns.vue?macro=true";
import { default as transactionslgjJ1jRxX6Meta } from "/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/transactions.vue?macro=true";
import { default as _91portfolioType_93Lz5OTVGOUJMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType].vue?macro=true";
import { default as _91id_93xL6fM6Wr70Meta } from "/usr/src/packages/stocks/pages/stocks/portfolios/details/[id].vue?macro=true";
import { default as detailsxa2DsXpMPrMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/details.vue?macro=true";
import { default as indexsNto0OwKRYMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/index.vue?macro=true";
import { default as indexRIwpw4c1lXMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/snp500/index.vue?macro=true";
import { default as _91sectorId_934XIx6fVjIbMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors/[sectorId].vue?macro=true";
import { default as indexJ5i4BUbMRaMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors/index.vue?macro=true";
import { default as sectors74tcQjPqt4Meta } from "/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors.vue?macro=true";
import { default as snp500mPrgrMS26sMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/snp500.vue?macro=true";
import { default as portfolioszi0VENqbE6Meta } from "/usr/src/packages/stocks/pages/stocks/portfolios.vue?macro=true";
import { default as searchT1B2Y8KjdfMeta } from "/usr/src/packages/stocks/pages/stocks/search.vue?macro=true";
import { default as top20QVDMEgSsSmMeta } from "/usr/src/packages/stocks/pages/stocks/top20.vue?macro=true";
import { default as stocksYNZBSUl7vEMeta } from "/usr/src/packages/stocks/pages/stocks.vue?macro=true";
import { default as callback0Nh93rez4dMeta } from "/usr/src/apps/base/src/pages/subscribe/callback.vue?macro=true";
import { default as termsofusel5AzRHu0H7Meta } from "/usr/src/apps/base/src/pages/termsofuse.vue?macro=true";
import { default as trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta } from "/usr/src/apps/base/src/pages/trademark-copyright-intellectual-property.vue?macro=true";
import { default as verify2fhYk5060fMeta } from "/usr/src/apps/base/src/pages/verify.vue?macro=true";
export default [
  {
    name: _91_46_46_46404_93dMgKFsN6Q6Meta?.name ?? "404",
    path: _91_46_46_46404_93dMgKFsN6Q6Meta?.path ?? "/:404(.*)*",
    meta: _91_46_46_46404_93dMgKFsN6Q6Meta || {},
    alias: _91_46_46_46404_93dMgKFsN6Q6Meta?.alias || [],
    redirect: _91_46_46_46404_93dMgKFsN6Q6Meta?.redirect,
    component: () => import("/usr/src/packages/error-handling/pages/[...404].vue").then(m => m.default || m)
  },
  {
    name: about4C1BTiVyMhMeta?.name ?? "about",
    path: about4C1BTiVyMhMeta?.path ?? "/about",
    meta: about4C1BTiVyMhMeta || {},
    alias: about4C1BTiVyMhMeta?.alias || [],
    redirect: about4C1BTiVyMhMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: accessibility08XNiHGQ0oMeta?.name ?? "accessibility",
    path: accessibility08XNiHGQ0oMeta?.path ?? "/accessibility",
    meta: accessibility08XNiHGQ0oMeta || {},
    alias: accessibility08XNiHGQ0oMeta?.alias || [],
    redirect: accessibility08XNiHGQ0oMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/accessibility.vue").then(m => m.default || m)
  },
  {
    name: contactwZAZ1OhQATMeta?.name ?? "contact",
    path: contactwZAZ1OhQATMeta?.path ?? "/contact",
    meta: contactwZAZ1OhQATMeta || {},
    alias: contactwZAZ1OhQATMeta?.alias || [],
    redirect: contactwZAZ1OhQATMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: datasecurityk9e8JnurInMeta?.name ?? "datasecurity",
    path: datasecurityk9e8JnurInMeta?.path ?? "/datasecurity",
    meta: datasecurityk9e8JnurInMeta || {},
    alias: datasecurityk9e8JnurInMeta?.alias || [],
    redirect: datasecurityk9e8JnurInMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/datasecurity.vue").then(m => m.default || m)
  },
  {
    name: disclosureIQD52U35CiMeta?.name ?? "disclosure",
    path: disclosureIQD52U35CiMeta?.path ?? "/disclosure",
    meta: disclosureIQD52U35CiMeta || {},
    alias: disclosureIQD52U35CiMeta?.alias || [],
    redirect: disclosureIQD52U35CiMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/disclosure.vue").then(m => m.default || m)
  },
  {
    name: forgotpassword3Ou1x7Xjk4Meta?.name ?? "forgotpassword",
    path: forgotpassword3Ou1x7Xjk4Meta?.path ?? "/forgotpassword",
    meta: forgotpassword3Ou1x7Xjk4Meta || {},
    alias: forgotpassword3Ou1x7Xjk4Meta?.alias || [],
    redirect: forgotpassword3Ou1x7Xjk4Meta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/forgotpassword.vue").then(m => m.default || m)
  },
  {
    name: indexqksRg9oJ1WMeta?.name ?? "index",
    path: indexqksRg9oJ1WMeta?.path ?? "/",
    meta: indexqksRg9oJ1WMeta || {},
    alias: indexqksRg9oJ1WMeta?.alias || [],
    redirect: indexqksRg9oJ1WMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginYIEPQC6mhGMeta?.name ?? "login",
    path: loginYIEPQC6mhGMeta?.path ?? "/login",
    meta: loginYIEPQC6mhGMeta || {},
    alias: loginYIEPQC6mhGMeta?.alias || [],
    redirect: loginYIEPQC6mhGMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: partnersnKP1uP9IicMeta?.name ?? "partners",
    path: partnersnKP1uP9IicMeta?.path ?? "/partners",
    meta: partnersnKP1uP9IicMeta || {},
    alias: partnersnKP1uP9IicMeta?.alias || [],
    redirect: partnersnKP1uP9IicMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: passwordresetawaitFAii2hbFCfMeta?.name ?? "passwordresetawait",
    path: passwordresetawaitFAii2hbFCfMeta?.path ?? "/passwordresetawait",
    meta: passwordresetawaitFAii2hbFCfMeta || {},
    alias: passwordresetawaitFAii2hbFCfMeta?.alias || [],
    redirect: passwordresetawaitFAii2hbFCfMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/passwordresetawait.vue").then(m => m.default || m)
  },
  {
    name: pricingOIBELzIw3pMeta?.name ?? "pricing",
    path: pricingOIBELzIw3pMeta?.path ?? "/pricing",
    meta: pricingOIBELzIw3pMeta || {},
    alias: pricingOIBELzIw3pMeta?.alias || [],
    redirect: pricingOIBELzIw3pMeta?.redirect,
    component: () => import("/usr/src/packages/pricing/pages/pricing.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91productId_93_93hqPFitOqkYMeta?.name ?? "pricing-productId",
    path: _91_91productId_93_93hqPFitOqkYMeta?.path ?? ":productId?",
    meta: _91_91productId_93_93hqPFitOqkYMeta || {},
    alias: _91_91productId_93_93hqPFitOqkYMeta?.alias || [],
    redirect: _91_91productId_93_93hqPFitOqkYMeta?.redirect,
    component: () => import("/usr/src/packages/pricing/pages/pricing/[[productId]].vue").then(m => m.default || m)
  }
]
  },
  {
    name: privacyW7FWgOcv9fMeta?.name ?? "privacy",
    path: privacyW7FWgOcv9fMeta?.path ?? "/privacy",
    meta: privacyW7FWgOcv9fMeta || {},
    alias: privacyW7FWgOcv9fMeta?.alias || [],
    redirect: privacyW7FWgOcv9fMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: profilezv8G5W5pJQMeta?.name ?? "profile",
    path: profilezv8G5W5pJQMeta?.path ?? "/profile",
    meta: profilezv8G5W5pJQMeta || {},
    alias: profilezv8G5W5pJQMeta?.alias || [],
    redirect: profilezv8G5W5pJQMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: infoFL8Bz5DpZJMeta?.name ?? "profile-info",
    path: infoFL8Bz5DpZJMeta?.path ?? "info",
    meta: infoFL8Bz5DpZJMeta || {},
    alias: infoFL8Bz5DpZJMeta?.alias || [],
    redirect: infoFL8Bz5DpZJMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/profile/info.vue").then(m => m.default || m)
  }
]
  },
  {
    name: signupD0zLV4v9noMeta?.name ?? "signup",
    path: signupD0zLV4v9noMeta?.path ?? "/signup",
    meta: signupD0zLV4v9noMeta || {},
    alias: signupD0zLV4v9noMeta?.alias || [],
    redirect: signupD0zLV4v9noMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: stocksYNZBSUl7vEMeta?.name ?? undefined,
    path: stocksYNZBSUl7vEMeta?.path ?? "/stocks",
    meta: stocksYNZBSUl7vEMeta || {},
    alias: stocksYNZBSUl7vEMeta?.alias || [],
    redirect: stocksYNZBSUl7vEMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks.vue").then(m => m.default || m),
    children: [
  {
    name: indexmzXPjhq5EgMeta?.name ?? "stocks",
    path: indexmzXPjhq5EgMeta?.path ?? "",
    meta: indexmzXPjhq5EgMeta || {},
    alias: indexmzXPjhq5EgMeta?.alias || [],
    redirect: indexmzXPjhq5EgMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/index.vue").then(m => m.default || m)
  },
  {
    name: indexlmLBSz9fOYMeta?.name ?? "stocks-momentum",
    path: indexlmLBSz9fOYMeta?.path ?? "momentum",
    meta: indexlmLBSz9fOYMeta || {},
    alias: indexlmLBSz9fOYMeta?.alias || [],
    redirect: indexlmLBSz9fOYMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/momentum/index.vue").then(m => m.default || m)
  },
  {
    name: negative3xD1YXaAWuMeta?.name ?? "stocks-momentum-negative",
    path: negative3xD1YXaAWuMeta?.path ?? "momentum/negative",
    meta: negative3xD1YXaAWuMeta || {},
    alias: negative3xD1YXaAWuMeta?.alias || [],
    redirect: negative3xD1YXaAWuMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/momentum/negative.vue").then(m => m.default || m)
  },
  {
    name: positivecfmu6LyIYtMeta?.name ?? "stocks-momentum-positive",
    path: positivecfmu6LyIYtMeta?.path ?? "momentum/positive",
    meta: positivecfmu6LyIYtMeta || {},
    alias: positivecfmu6LyIYtMeta?.alias || [],
    redirect: positivecfmu6LyIYtMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/momentum/positive.vue").then(m => m.default || m)
  },
  {
    name: portfolioszi0VENqbE6Meta?.name ?? undefined,
    path: portfolioszi0VENqbE6Meta?.path ?? "portfolios",
    meta: portfolioszi0VENqbE6Meta || {},
    alias: portfolioszi0VENqbE6Meta?.alias || [],
    redirect: portfolioszi0VENqbE6Meta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios.vue").then(m => m.default || m),
    children: [
  {
    name: _91portfolioType_93Lz5OTVGOUJMeta?.name ?? undefined,
    path: _91portfolioType_93Lz5OTVGOUJMeta?.path ?? ":portfolioType()",
    meta: _91portfolioType_93Lz5OTVGOUJMeta || {},
    alias: _91portfolioType_93Lz5OTVGOUJMeta?.alias || [],
    redirect: _91portfolioType_93Lz5OTVGOUJMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType].vue").then(m => m.default || m),
    children: [
  {
    name: indexPDJrGGTxBuMeta?.name ?? "stocks-portfolios-portfolioType",
    path: indexPDJrGGTxBuMeta?.path ?? "",
    meta: indexPDJrGGTxBuMeta || {},
    alias: indexPDJrGGTxBuMeta?.alias || [],
    redirect: indexPDJrGGTxBuMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/index.vue").then(m => m.default || m)
  },
  {
    name: returnsnxcTGiYrnyMeta?.name ?? "stocks-portfolios-portfolioType-returns",
    path: returnsnxcTGiYrnyMeta?.path ?? "returns",
    meta: returnsnxcTGiYrnyMeta || {},
    alias: returnsnxcTGiYrnyMeta?.alias || [],
    redirect: returnsnxcTGiYrnyMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/returns.vue").then(m => m.default || m)
  },
  {
    name: transactionslgjJ1jRxX6Meta?.name ?? "stocks-portfolios-portfolioType-transactions",
    path: transactionslgjJ1jRxX6Meta?.path ?? "transactions",
    meta: transactionslgjJ1jRxX6Meta || {},
    alias: transactionslgjJ1jRxX6Meta?.alias || [],
    redirect: transactionslgjJ1jRxX6Meta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/transactions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: detailsxa2DsXpMPrMeta?.name ?? "stocks-portfolios-details",
    path: detailsxa2DsXpMPrMeta?.path ?? "details",
    meta: detailsxa2DsXpMPrMeta || {},
    alias: detailsxa2DsXpMPrMeta?.alias || [],
    redirect: detailsxa2DsXpMPrMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/details.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93xL6fM6Wr70Meta?.name ?? "stocks-portfolios-details-id",
    path: _91id_93xL6fM6Wr70Meta?.path ?? ":id()",
    meta: _91id_93xL6fM6Wr70Meta || {},
    alias: _91id_93xL6fM6Wr70Meta?.alias || [],
    redirect: _91id_93xL6fM6Wr70Meta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/details/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexsNto0OwKRYMeta?.name ?? "stocks-portfolios",
    path: indexsNto0OwKRYMeta?.path ?? "",
    meta: indexsNto0OwKRYMeta || {},
    alias: indexsNto0OwKRYMeta?.alias || [],
    redirect: indexsNto0OwKRYMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/index.vue").then(m => m.default || m)
  },
  {
    name: snp500mPrgrMS26sMeta?.name ?? undefined,
    path: snp500mPrgrMS26sMeta?.path ?? "snp500",
    meta: snp500mPrgrMS26sMeta || {},
    alias: snp500mPrgrMS26sMeta?.alias || [],
    redirect: snp500mPrgrMS26sMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500.vue").then(m => m.default || m),
    children: [
  {
    name: indexRIwpw4c1lXMeta?.name ?? "stocks-portfolios-snp500",
    path: indexRIwpw4c1lXMeta?.path ?? "",
    meta: indexRIwpw4c1lXMeta || {},
    alias: indexRIwpw4c1lXMeta?.alias || [],
    redirect: indexRIwpw4c1lXMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/index.vue").then(m => m.default || m)
  },
  {
    name: sectors74tcQjPqt4Meta?.name ?? undefined,
    path: sectors74tcQjPqt4Meta?.path ?? "sectors",
    meta: sectors74tcQjPqt4Meta || {},
    alias: sectors74tcQjPqt4Meta?.alias || [],
    redirect: sectors74tcQjPqt4Meta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors.vue").then(m => m.default || m),
    children: [
  {
    name: _91sectorId_934XIx6fVjIbMeta?.name ?? "stocks-portfolios-snp500-sectors-sectorId",
    path: _91sectorId_934XIx6fVjIbMeta?.path ?? ":sectorId()",
    meta: _91sectorId_934XIx6fVjIbMeta || {},
    alias: _91sectorId_934XIx6fVjIbMeta?.alias || [],
    redirect: _91sectorId_934XIx6fVjIbMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors/[sectorId].vue").then(m => m.default || m)
  },
  {
    name: indexJ5i4BUbMRaMeta?.name ?? "stocks-portfolios-snp500-sectors",
    path: indexJ5i4BUbMRaMeta?.path ?? "",
    meta: indexJ5i4BUbMRaMeta || {},
    alias: indexJ5i4BUbMRaMeta?.alias || [],
    redirect: indexJ5i4BUbMRaMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: searchT1B2Y8KjdfMeta?.name ?? "stocks-search",
    path: searchT1B2Y8KjdfMeta?.path ?? "search",
    meta: searchT1B2Y8KjdfMeta || {},
    alias: searchT1B2Y8KjdfMeta?.alias || [],
    redirect: searchT1B2Y8KjdfMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/search.vue").then(m => m.default || m)
  },
  {
    name: top20QVDMEgSsSmMeta?.name ?? "stocks-top20",
    path: top20QVDMEgSsSmMeta?.path ?? "top20",
    meta: top20QVDMEgSsSmMeta || {},
    alias: top20QVDMEgSsSmMeta?.alias || [],
    redirect: top20QVDMEgSsSmMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/top20.vue").then(m => m.default || m)
  }
]
  },
  {
    name: callback0Nh93rez4dMeta?.name ?? "subscribe-callback",
    path: callback0Nh93rez4dMeta?.path ?? "/subscribe/callback",
    meta: callback0Nh93rez4dMeta || {},
    alias: callback0Nh93rez4dMeta?.alias || [],
    redirect: callback0Nh93rez4dMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/subscribe/callback.vue").then(m => m.default || m)
  },
  {
    name: termsofusel5AzRHu0H7Meta?.name ?? "termsofuse",
    path: termsofusel5AzRHu0H7Meta?.path ?? "/termsofuse",
    meta: termsofusel5AzRHu0H7Meta || {},
    alias: termsofusel5AzRHu0H7Meta?.alias || [],
    redirect: termsofusel5AzRHu0H7Meta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/termsofuse.vue").then(m => m.default || m)
  },
  {
    name: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta?.name ?? "trademark-copyright-intellectual-property",
    path: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta?.path ?? "/trademark-copyright-intellectual-property",
    meta: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta || {},
    alias: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta?.alias || [],
    redirect: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/trademark-copyright-intellectual-property.vue").then(m => m.default || m)
  },
  {
    name: verify2fhYk5060fMeta?.name ?? "verify",
    path: verify2fhYk5060fMeta?.path ?? "/verify",
    meta: verify2fhYk5060fMeta || {},
    alias: verify2fhYk5060fMeta?.alias || [],
    redirect: verify2fhYk5060fMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/verify.vue").then(m => m.default || m)
  }
]